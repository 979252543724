export const colors = {
    blue:    "#5664d2",
    indigo:  "#564ab1",
    purple:  "#5664d2",
    pink:    "#e83e8c",
    red:     "#ff3d60",
    orange:  "#f1734f",
    yellow:  "#fcb92c",
    green:   "#597E52",
    teal:    "#050505",
    cyan:    "#4aa3ff",
    brown :  "#65451F"
  }