// config.js

const config = {
    apiUrl: 'https://api.riam.servermdz.com',
    wsUrl : "ws://localhost:8081/ws",
    apiKey: '53666584f14702461b417a3b431278471be4c1f82edd7a385c1c01c4',
    
  };
  
  export default config;
  